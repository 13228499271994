<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap25JsonAndXml"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 25: JSON and XML" image-name="encoding-decoding.jpg" image-alt="JSON and XML"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is encoding/decoding?</p></li>
<li><p>What are JSON and XML?</p></li>
<li><p>What are marshaling and unmarshaling?</p></li>
<li><p>How to convert a variable into JSON and XML.</p></li>
<li><p>How to convert a JSON or XML string (or file) into a variable.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Encode / Decode</p></li>
<li><p>Marshal / Unmarshal</p></li>
<li><p>Data stream</p></li>
<li><p>JSON</p></li>
<li><p>XML</p></li>
<li><p>interface implementation</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>JSON and XML are two formats that are used in modern applications. They are very convenient because they can be read and easily understood by humans in comparison to other formats (like protocol buffers, for instance see <a href="#par:A-word-about" data-reference-type="ref" data-reference="par:A-word-about">[par:A-word-about]</a>)</p>
<div id="encoding-decoding" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Encoding / Decoding <a href="#encoding-decoding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Most of the time, applications transmit data to other applications, or to humans. In a Go program, the data that we manipulate is often in the form of a type struct :</p>
<pre v-highlightjs><code class="go" v-pre >type Cart struct {
    ID   string
    Paid bool
}
cart := Cart{
    ID:   &quot;121514&quot;,
    Paid: false,
}</code></pre>
<p>What if we want to transmit the data in the <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> variable to another program? What if this program is not written in Go ? That’s why we need encoding.</p>
<ul>
<li><strong>Encoding is the process of transforming a piece of data into a “coded form”</strong>.</li>
</ul>
<p>When transmitting a message through a communication channel, we want to make sure that the receiver will understand the message. Hence the sender and the receiver will decide which encoding technique they will use. The term encoding technique might sound unfamiliar, but you use the process of encoding every day... When we speak to another individual, we encode our thoughts into a coded form: the language (English, French, French Canadian, Mandarin,...). When you speak, you encode your thoughts. (if you think before you speak, of course). The language that we use has a set of rules that we have to respect to make our message understandable (syntax, grammar ...). This is the same for all “coded form”.</p>
<ul>
<li><strong>The “coded form” can be transformed back into the original message thanks to decoding.</strong></li>
</ul>
<p>In this section, we will study two “coded forms” : JSON and XML.</p>
<div id="marshal-unmarshal" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Marshal / Unmarshal <a href="#marshal-unmarshal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the next section, you will see the term Marshal and Unmarshal appear. Marshaling and unmarshaling also denote a process.</p>
<ul>
<li><p>Marshaling is the process of transforming an <strong>object</strong> into a <strong>storable</strong> representation using a specific format.</p>
<ul>
<li>We can marshal a variable of type struct in JSON, for example</li>
</ul></li>
<li><p>Unmarshaling is the reverse process.</p></li>
</ul>
<p>“Marshaling” refers to the same concept as “encoding”. However, when you use this term, you explicitly say that you will work with precisely defined objects (ex: a map, a slice, a variable of type struct...). An encoder operates on <strong>a stream</strong> of data, a marshaler operates on a data structure, an object.</p>
<p>You might ask what a data stream is? It is a flow of data that arrives into a system. For instance, a system can receive data streams, flows of data through a network connection. Handling a data stream can be a complex task because data can arrive in chunks, and it can arrive in an unordered manner. Chunks may also vary in size, which can lead to memory issues.</p>
<p><strong>Conclusion</strong> : we encode a stream of data, we marshal a data structure.</p>
<div id="what-is-json" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> What is JSON <a href="#what-is-json"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>JSON means JavaScript Object Notation. This is a text format that is used to encode/decode <strong>structured</strong> data. It has been specified first by Douglas Crockford.</p>
<p>Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >[
  {
    &quot;id&quot;: 1,
    &quot;first_name&quot;: &quot;Annalise&quot;,
    &quot;last_name&quot;: &quot;Marlin&quot;,
    &quot;city&quot;: &quot;Bang Bon&quot;
  },
  {
    &quot;id&quot;: 2,
    &quot;first_name&quot;: &quot;Dacy&quot;,
    &quot;last_name&quot;: &quot;Biffin&quot;,
    &quot;city&quot;: &quot;Cuilco&quot;
  },
  {
    &quot;id&quot;: 3,
    &quot;first_name&quot;: &quot;Wendye&quot;,
    &quot;last_name&quot;: &quot;Taillard&quot;,
    &quot;city&quot;: &quot;Preobrazhenka&quot;
  }
]</code></pre>
<ul>
<li><p>We have an array of 3 user objects.</p></li>
<li><p>The array starts with <span v-highlightjs><code class="go" v-pre style="display: inline">[</code></span> and ends with <span v-highlightjs><code class="go" v-pre style="display: inline">]</code></span></p></li>
<li><p>Each object starts with a curly brace and ends with a curly brace. (<span v-highlightjs><code class="go" v-pre style="display: inline">{}</code></span>)</p></li>
<li><p>Each user property is written with a key-value pair format.</p>
<pre v-highlightjs><code class="go" v-pre >&quot;id&quot;: 3</code></pre></li>
</ul>
<div id="unmarshal-json" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Unmarshal JSON <a href="#unmarshal-json"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To decode a string containing JSON, you will need to create type structs. Let’s take an example.</p>
<p>Imagine that you want to decode the following JSON string :</p>
<pre v-highlightjs><code class="go" v-pre >{
  &quot;cat&quot;: {
    &quot;name&quot;: &quot;Joey&quot;,
    &quot;age&quot;: 8
  }
}</code></pre>
<p>We have an object with a property cat equal to another object with two properties: <span v-highlightjs><code class="go" v-pre style="display: inline">name</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">age</code></span>. When you want to decode, you first have to create a struct that will hold the data.</p>
<p>We will first create a struct that holds the cat struct :</p>
<pre v-highlightjs><code class="go" v-pre >type MyJson struct {
    Cat
}</code></pre>
<p>Then we will define the <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> struct :</p>
<pre v-highlightjs><code class="go" v-pre >type Cat struct {
    Name string
    Age uint
}</code></pre>
<p>We have two fields, name and age, similar to the JSON structure that we want to decode. We can now launch the decoding function :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/unmarshall-json/main.go 

func main() {
    myJson := []byte(`{&quot;cat&quot;:{ &quot;name&quot;:&quot;Joey&quot;, &quot;age&quot;:8}}`)
    c := MyJson{}
    err := json.Unmarshal(myJson, &amp;c)
    if err != nil {
        panic(err)
    }
    fmt.Println(c.Cat.Name)
    fmt.Println(c.Cat.Age)
}</code></pre>
<p>We first need to convert the string to a slice of bytes. Then we create a new empty <span v-highlightjs><code class="go" v-pre style="display: inline">MyJson</code></span> struct.</p>
<p>Then we call <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> to set the values of our variable <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span>. Note that <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> arguments are :</p>
<ul>
<li><p>A slice of bytes that represent the JSON to decode (<span v-highlightjs><code class="go" v-pre style="display: inline">myJson</code></span>)</p></li>
<li><p>A pointer to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> (<span v-highlightjs><code class="go" v-pre style="display: inline">&amp;c</code></span>)</p></li>
</ul>
<p>Why do we need to pass a pointer? Because the <span v-highlightjs><code class="go" v-pre style="display: inline">Unmarshal</code></span> function will modify the value <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span>.</p>
<p>What is the expected output of the following code?</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(c.Cat.Name)
fmt.Println(c.Cat.Age)</code></pre>
<p>We expect to see :</p>
<pre v-highlightjs><code class="go" v-pre >Joey
8</code></pre>
<p>But if you run the previous code, you will see that nothing is output! Why?</p>
<p>Because <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> didn’t make a successful matching of the fields! <span v-highlightjs><code class="go" v-pre style="display: inline">Unmarshal</code></span> <strong>will take the name of the fields of our struct and try to find a corresponding property in the JSON string.</strong> If you look closely at our struct, we have capitalized the first letters to export the fields.</p>
<div id="solutions" class="anchor"></div>
<h4 data-number="7.0.0.1"><span class="header-section-number">7.0.0.1</span> Solutions <a href="#solutions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>We can make our fields unexported (we remove the first capitalized letter). This is not a good idea. An external format to our application should not interfere with our application types!</p></li>
<li><p>We need to get the ability to define other field names decorrelated from the field name in the JSON string.</p></li>
</ol>
<p>Solution 2 seems difficult. In reality, it’s not. We will use <strong>tags</strong>.</p>
<div id="struct-tags" class="anchor"></div>
<h4 data-number="7.0.0.2"><span class="header-section-number">7.0.0.2</span> Struct Tags <a href="#struct-tags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Tags are small <strong>string literals</strong> that can follow a field declaration. Tags are accessible to the decoder. It will use them to decode the JSON string correctly.</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/unmarshall-json-tags/main.go 

type MyJson struct {
    Cat `json:&quot;cat&quot;`
}

type Cat struct {
    Name string `json:&quot;name&quot;`
    Age  uint   `json:&quot;age&quot;`
}</code></pre>
<p>You see that we added here three tags to the fields of the type structs. Tags always have the same construction :</p>
<pre v-highlightjs><code class="go" v-pre >`json:&quot;nameOfTheFieldInJson&quot;`</code></pre>
<p>The Unmarshaler will be able to find the fields in the JSON. They are accessible via the <span v-highlightjs><code class="go" v-pre style="display: inline">reflect</code></span> package (more precisely the <span v-highlightjs><code class="go" v-pre style="display: inline">tag</code></span> is a field of the struct <span v-highlightjs><code class="go" v-pre style="display: inline">reflect.StructField</code></span>)</p>
<figure>
<b-img :src="require('@/assets/images/type_struct_to_json.png')" alt="Type Struct to JSON with struct tags"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Type Struct to JSON with struct tags</figcaption>
</figure>
<div id="marshal-json" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Marshal JSON <a href="#marshal-json"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To create a JSON string in go, you have to define a type struct that will define your data structure. We will take the example of an e-commerce website that references products. Our objective is to create a JSON string from a slice of products.</p>
<p>We first define the <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> type struct and the <span v-highlightjs><code class="go" v-pre style="display: inline">Category</code></span> type struct (an article belong to a unique category) :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    ID    uint64
    Name  string
    SKU   string
    Cat   Category
}
type Category struct {
    ID   uint64
    Name string
}</code></pre>
<p>Then we will create a product, more precisely, a Tea Pot.</p>
<pre v-highlightjs><code class="go" v-pre >p := Product{ID: 42, Name: &quot;Tea Pot&quot;, SKU: &quot;TP12&quot;, Category: Category{ID: 2, Name: &quot;Tea&quot;}}</code></pre>
<p>Once we have our variable, we can launch the json.Marshal function :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/marshall-json/main.go 

b, err := json.Marshal(p)
if err != nil {
    panic(err)
}
fmt.Println(string(b))</code></pre>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> is a slice of bytes. Note that the encoding operation might result in an error. We catch this error, and we make our program panic<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. Then we can print our result :</p>
<pre v-highlightjs><code class="go" v-pre >{&quot;ID&quot;:42,&quot;Name&quot;:&quot;Tea Pot&quot;,&quot;SKU&quot;:&quot;TP12&quot;,&quot;Price&quot;:30.5,&quot;Category&quot;:{&quot;ID&quot;:2,&quot;Name&quot;:&quot;Tea&quot;}}</code></pre>
<p>The formatting of the string is not optimal. We can use instead of another function to encode the JSON but also to indent it :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/marshall-json/main.go 

bI, err := json.MarshalIndent(p,&quot;&quot;,&quot;    &quot;)
if err != nil {
    panic(err)
}
fmt.Println(string(bI))</code></pre>
<p>The arguments of <span v-highlightjs><code class="go" v-pre style="display: inline">json.MarshalIndent</code></span> are :</p>
<ul>
<li><p>the <strong>data</strong> to marshal</p></li>
<li><p>the <strong>prefix</strong> string (every line of the output result will start with this string). In the example, this parameter is populated with an empty string.</p></li>
<li><p>the <strong>indent</strong> string. In the example, we put a tab.</p></li>
</ul>
<p>The outputed result is :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;ID&quot;: 42,
    &quot;Name&quot;: &quot;Tea Pot&quot;,
    &quot;SKU&quot;: &quot;TP12&quot;,
    &quot;Category&quot;: {
        &quot;ID&quot;: 2,
        &quot;Name&quot;: &quot;Tea&quot;
    }
}</code></pre>
<p>Usually, in JSON, the properties’ names do not begin with a capitalized letter. Here, the json package has simply taken the name of our <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> struct’s fields without any modification.</p>
<p>Tags can be added to the <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> struct to control the printed result :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/marshall-json/main.go 

type Product struct {
    ID       uint64   `json:&quot;id&quot;`
    Name     string   `json:&quot;name&quot;`
    SKU      string   `json:&quot;sku&quot;`
    Category Category `json:&quot;category&quot;`
}
type Category struct {
    ID   uint64 `json:&quot;id&quot;`
    Name string `json:&quot;name&quot;`
}</code></pre>
<p>The outputed result is now :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;id&quot;: 42,
    &quot;name&quot;: &quot;Tea Pot&quot;,
    &quot;sku&quot;: &quot;TP12&quot;,
    &quot;category&quot;: {
        &quot;id&quot;: 2,
        &quot;name&quot;: &quot;Tea&quot;
    }
}</code></pre>
<div id="struct-tags-1" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Struct tags <a href="#struct-tags-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the two previous sections we have seen that we can control the structure of the encoded JSON with tags. We have also learned how to decode a JSON string and make our structure’s field name match the JSON properties’ name.</p>
<div id="how-to-ignore-empty-fields" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> How to ignore empty fields? <a href="#how-to-ignore-empty-fields"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If one of the fields of your variable is not set, it will have the default zero value. By default, it will be present in the JSON string generated by <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshal</code></span> or <span v-highlightjs><code class="go" v-pre style="display: inline">json.MarshalIndent</code></span> <strong>:</strong></p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/tags/main.go 

type Product struct {
    ID   uint64 `json:&quot;id&quot;`
    Name string `json:&quot;name&quot;`
}

func main() {
    p := Product{ID: 42}
    bI, err := json.MarshalIndent(p, &quot;&quot;, &quot;  &quot;)
    if err != nil {
        panic(err)
    }
    fmt.Println(string(bI))
}</code></pre>
<p>The script will print :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;id&quot;: 42,
    &quot;name&quot;: &quot;&quot;
}</code></pre>
<p>Here the field name is an empty string. We can add the <span v-highlightjs><code class="go" v-pre style="display: inline">omitempty</code></span> directive into the struct tag to make it disappear from the outputed JSON :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    ID   uint64 `json:&quot;id&quot;`
    Name string `json:&quot;name,omitempty&quot;`
}</code></pre>
<p>The result is now :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;id&quot;: 42
}</code></pre>
<p>The name property has been omitted because it was empty.</p>
<div id="skip-a-field" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Skip a field <a href="#skip-a-field"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The use case is simple, you have a struct with ten fields, and you want to hide it in the encoded version of your JSON string (for instance, the field is no longer used by your clients). What you could do is to remove it from your struct. That solution is not the good one if you still use this field in the rest of your code.</p>
<p>There is a directive for that : <span v-highlightjs><code class="go" v-pre style="display: inline">-</code></span></p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/tags/main.go 

type Product struct {
    ID          uint64 `json:&quot;id&quot;`
    Name        string `json:&quot;name,omitempty&quot;`
    Description string `json:&quot;-&quot;`
}</code></pre>
<p>Here we are telling the json package not to output the field <span v-highlightjs><code class="go" v-pre style="display: inline">Description</code></span> in the encoded string.</p>
<div id="how-to-parse-the-tags-of-your-struct" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> How to parse the tags of your struct <a href="#how-to-parse-the-tags-of-your-struct"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can obtain the value of tags in a type struct by using the package reflect :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/access-tags/main.go 

type Product struct {
    ID          uint64 `json:&quot;id&quot;`
    Name        string `json:&quot;name,omitempty&quot;`
    Description string `json:&quot;-&quot;`
}

func main() {
    p:= Product{ID:32}
    t := reflect.TypeOf(p)
    for i := 0; i &lt; t.NumField(); i++ {
        fmt.Printf(&quot;field Name : %s\n&quot;,t.Field(i).Name)
        fmt.Printf(&quot;field Tag : %s\n&quot;,t.Field(i).Tag)
    }
}</code></pre>
<p>Here we first define the <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> type that contains three fields with tags on each field. Then in the main function, we create a new variable (of type <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span>) named <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span>. The type data is retrieved with the <span v-highlightjs><code class="go" v-pre style="display: inline">TypeOf</code></span> function of the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">reflect</code></span>.</p>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">TypeOf</code></span> returns an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">Type</code></span> ! The method <span v-highlightjs><code class="go" v-pre style="display: inline">NumField()</code></span> will return the count of fields in the struct. In our case, it will return 3.</p>
<p>We will then iterate over the fields of the struct with the help of the method <span v-highlightjs><code class="go" v-pre style="display: inline">Field()</code></span> that takes the <strong>field index</strong> as argument. This last function will return a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">StructField</code></span><strong>:</strong></p>
<pre v-highlightjs><code class="go" v-pre >// package reflect
// file: type.go
type StructField struct {
    Name      string
    PkgPath   string
    Type      Type
    Tag       StructTag
    Offset    uintptr
    Index     []int
    Anonymous bool
}</code></pre>
<p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">StructTag</code></span> wraps a simple string. You have also access to conventional methods to manipulate the tags : <span v-highlightjs><code class="go" v-pre style="display: inline">Get()</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Lookup()</code></span> Let’s take an example for the <span v-highlightjs><code class="go" v-pre style="display: inline">Get</code></span> method :</p>
<pre v-highlightjs><code class="go" v-pre >//...
// for loop
    fmt.Println(t.Field(i).Tag.Get(&quot;json&quot;))
//...</code></pre>
<p>In the context of the previous code snippet this will output :</p>
<pre v-highlightjs><code class="go" v-pre >id
name,omitempty
-</code></pre>
<p>And now an example for the <span v-highlightjs><code class="go" v-pre style="display: inline">Lookup</code></span> method. The purpose of the <span v-highlightjs><code class="go" v-pre style="display: inline">Lookup</code></span> method is to find whether a tag is present or not :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/access-tags/main.go 

if tagValue, ok := t.Field(i).Tag.Lookup(&quot;test&quot;); ok {
    fmt.Println(tagValue)
} else {
    fmt.Println(&quot;no tag &#39;test&#39;&quot;)
}</code></pre>
<div id="how-to-create-your-tags" class="anchor"></div>
<h2 data-number="9.4"><span class="header-section-number">9.4</span> How to create your tags <a href="#how-to-create-your-tags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>JSON and XML tags are convenient; you might want to create your own struct tags for special use cases. The language expects a certain syntax:</p>
<figure>
<b-img :src="require('@/assets/images/struct_tag.png')" alt="Struct Tag format[fig:Struct-Tag-format]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Struct Tag format<span id="fig:Struct-Tag-format" label="fig:Struct-Tag-format">[fig:Struct-Tag-format]</span></figcaption>
</figure>
<p>A tag is organized as a list of key-value elements. In the figure <a href="#fig:Struct-Tag-format" data-reference-type="ref" data-reference="fig:Struct-Tag-format">1</a> you can see that we have two elements. The first element has the <strong>json</strong> key and the value <strong>name, omitempty.</strong>The second element has the key <strong>xml</strong> and its value is <strong>Name.</strong> Note that elements are separated by <strong>spaces</strong>.</p>
<p>If we follow this model, we can create the following tag with three keys.</p>
<pre v-highlightjs><code class="go" v-pre >`myKey1:&quot;test,test2&quot; myKey2:&quot;yo&quot; myKey3:&quot;yoyooo&quot;`</code></pre>
<div id="marshal-unmarshal-xml" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Marshal / Unmarshal XML <a href="#marshal-unmarshal-xml"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>XML means e <strong>X</strong>tensible <strong>M</strong>arkup <strong>L</strong>anguage. It’s a file format that is designed to store information in a structured way. It’s still used today in certain domain, but it tends to decline in usage; mainly because it’s verbose (the messages encoded in XML need more space to be stored than JSON, for instance.</p>
<p>For instance, this is the XML encoded product object :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;Product&gt;
    &lt;id&gt;42&lt;/id&gt;
    &lt;name&gt;Tea Pot&lt;/name&gt;
    &lt;sku&gt;TP12&lt;/sku&gt;
    &lt;category&gt;
        &lt;id&gt;2&lt;/id&gt;
        &lt;name&gt;Tea&lt;/name&gt;
    &lt;/category&gt;
&lt;/Product&gt;</code></pre>
<p>And here is its equivalent in JSON :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;id&quot;: 42,
    &quot;name&quot;: &quot;Tea Pot&quot;,
    &quot;sku&quot;: &quot;TP12&quot;,
    &quot;category&quot;: {
        &quot;id&quot;: 2,
        &quot;name&quot;: &quot;Tea&quot;
    }
}</code></pre>
<ul>
<li><p>JSON needs 95 chars</p></li>
<li><p>XML needs 111 chars to encode the same object</p></li>
</ul>
<p>Decode XML is as easy as decode JSON.</p>
<p>First, you define a struct that will hold your data :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/decode/main.go 

type MyXML struct {
    Cat `xml:&quot;cat&quot;`
}

type Cat struct {
    Name string `xml:&quot;name&quot;`
    Age  uint   `xml:&quot;age&quot;`
}</code></pre>
<p>We added XML struct tags to the struct (il allow the decoder to know where to find the fields’ values).</p>
<p>Then you can call the <span v-highlightjs><code class="go" v-pre style="display: inline">xml.Unmarshal</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/decode/main.go 

func main() {
    myXML := []byte(`&lt;cat&gt;
    &lt;name&gt;Ti&lt;/name&gt;
    &lt;age&gt;23&lt;/age&gt;
&lt;/cat&gt;`)
    c := MyXML{}
    err := xml.Unmarshal(myXML, &amp;c)
    if err != nil {
        panic(err)
    }
    fmt.Println(c.Cat.Name)
    fmt.Println(c.Cat.Age)
}</code></pre>
<p>Encoding XML is also very easy; you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">xml.Marshal</code></span>, or like here, the function <span v-highlightjs><code class="go" v-pre style="display: inline">xml.MarshalIndent</code></span> to display a pretty xml string :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/encode/main.go 

func main() {
    p := Product{ID: 42, Name: &quot;Tea Pot&quot;, SKU: &quot;TP12&quot;, Price: 30.5, Category: Category{ID: 2, Name: &quot;Tea&quot;}}
    bI, err := xml.MarshalIndent(p, &quot;&quot;, &quot;   &quot;)
    if err != nil {
        panic(err)
    }
    xmlWithHeader := xml.Header + string(bI)
    fmt.Println(xmlWithHeader)
}</code></pre>
<p>Remember that you have to add a header to your XML :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;?xml version=&quot;1.0&quot; encoding=&quot;UTF-8&quot;?&gt;</code></pre>
<p>This header will give important information to the systems that will use your xml document. It gives information about the version of XML used (“1.0” in our case that dates back to 1998) and the encoding of your document. The parser will need this information to decode your document correctly.</p>
<p>The xml package defines a constant that you can use directly :</p>
<pre v-highlightjs><code class="go" v-pre >xmlWithHeader := xml.Header + string(bI)</code></pre>
<p>Here is the XML produced :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;?xml version=&quot;1.0&quot; encoding=&quot;UTF-8&quot;?&gt;
&lt;Product&gt;
    &lt;id&gt;42&lt;/id&gt;
    &lt;name&gt;Tea Pot&lt;/name&gt;
    &lt;sku&gt;TP12&lt;/sku&gt;
    &lt;price&gt;30.5&lt;/price&gt;
    &lt;category&gt;
        &lt;id&gt;2&lt;/id&gt;
        &lt;name&gt;Tea&lt;/name&gt;
    &lt;/category&gt;
&lt;/Product&gt;</code></pre>
<div id="xml.name-type" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> xml.Name type <a href="#xml.name-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If you create a variable of the following type</p>
<pre v-highlightjs><code class="go" v-pre >type MyXmlElement struct {
    Name  string `xml:&quot;name&quot;`
}</code></pre>
<p>and if you marshal it, the result will be :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;MyXmlElement&gt;
    &lt;name&gt;Testing&lt;/name&gt;
&lt;/MyXmlElement&gt;</code></pre>
<p>We can control the marshaling process of the property “name”, but we do not have control over the way the element <span v-highlightjs><code class="go" v-pre style="display: inline">MyXmlElement</code></span><strong>.</strong> For the moment, if we want to change the name of this property to something else, we have to change the name of our type... Which is not very convenient.</p>
<p>You can add a special field to your struct to control the name of the XML element :</p>
<pre v-highlightjs><code class="go" v-pre >type MyXmlElement struct {
    XMLName xml.Name `xml:&quot;myOwnName&quot;`
    Name  string `xml:&quot;name&quot;`
}</code></pre>
<p>The field has to be named <span v-highlightjs><code class="go" v-pre style="display: inline">XMLName</code></span><strong>,</strong>the type must be <span v-highlightjs><code class="go" v-pre style="display: inline">xml.Name</code></span> and in the tag, you can put the desired name of the element (that will be injected during the marshaling process).</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/xmlNameType/main.go
package main

import (
    &quot;encoding/xml&quot;
    &quot;fmt&quot;
)

type MyXmlElement struct {
    XMLName xml.Name `xml:&quot;myOwnName&quot;`
    Name    string   `xml:&quot;name&quot;`
}

func main() {
    elem := MyXmlElement{Name: &quot;Testing&quot;}
    m, err := xml.MarshalIndent(elem, &quot;&quot;, &quot; &quot;)
    if err != nil {
        panic(err)
    }
    fmt.Println(string(m))

}</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;myOwnName&gt;
    &lt;name&gt;Testing&lt;/name&gt;
&lt;/myOwnName&gt;</code></pre>
<div id="specificity-of-xml-tags" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Specificity of XML tags <a href="#specificity-of-xml-tags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In addition to the omit empty (<span v-highlightjs><code class="go" v-pre style="display: inline">omitempty</code></span>) and the ignore directive (“<span v-highlightjs><code class="go" v-pre style="display: inline">-</code></span>”) the standard library offers you a large palette of tag values :</p>
<div id="attributes" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Attributes <a href="#attributes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can add specific attributes to a field with XML. For instance :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;price currency=&quot;EUR&quot;&gt;123&lt;/price&gt;</code></pre>
<p>Here the field is named <span v-highlightjs><code class="go" v-pre style="display: inline">price</code></span> and there is an attribute named <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span>. You can use the following struct type to encode/decode it :</p>
<pre v-highlightjs><code class="go" v-pre >type Price struct {
    Text     string `xml:&quot;,chardata&quot;`
    Currency string `xml:&quot;currency,attr&quot;`
}

type Product struct {
    Price Price  `xml:&quot;price&quot;`
    Name  string `xml:&quot;name&quot;`
}</code></pre>
<p>We have defined a struct named Price with two fields : <span v-highlightjs><code class="go" v-pre style="display: inline">Text</code></span> that will contain the price (“123” in the example)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Currency</code></span> that will contain the value of the <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span> attribute (“EUR” in the example) Note the tags used. To get the price, the tag is</p>
<pre v-highlightjs><code class="go" v-pre >`xml:&quot;,chardata&quot;`</code></pre>
<p>It tells Go to get the data contained between the tags opening and the closing<span v-highlightjs><code class="go" v-pre style="display: inline">price</code></span> tag. To get attributes, you have to use the keyword <span v-highlightjs><code class="go" v-pre style="display: inline">attr</code></span> and the attribute name. The field is written as “character data” not has an xml element.</p>
<pre v-highlightjs><code class="go" v-pre >`xml:&quot;currency,attr&quot;`</code></pre>
<p>You always begin with the name of the attribute.</p>
<figure>
<b-img :src="require('@/assets/images/xml_attr.png')" alt="XML attribute and struct tags"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">XML attribute and struct tags</figcaption>
</figure>
<div id="cdata" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> CDATA <a href="#cdata"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you want to transfer XML via XML, you can use CDATA fields. CDATA fields allow you to inject characters like &gt; and &lt; into your XML message. If you inject that kind of data without a CDATA field, your XML could become invalid.</p>
<p>Let’s take an example. We add to our XML a field <span v-highlightjs><code class="go" v-pre style="display: inline">myXml</code></span> which is populated with character data. Note that character data is enclosed by <span v-highlightjs><code class="go" v-pre style="display: inline">![CDATA[*and \lstinline!]]&gt;!\textbf{.} The value of this field is therefore \lstinline!</code></span>yo&gt;12&lt;/yo&gt;!</p>
<pre v-highlightjs><code class="go" v-pre >&lt;product&gt;
    &lt;price currency=&quot;EUR&quot;&gt;123&lt;/price&gt;
    &lt;myXml&gt;&lt;![CDATA[&lt;yo&gt;12&lt;/yo&gt;]]&gt;&lt;/myXml&gt;
    &lt;name&gt;Kit&lt;/name&gt;
&lt;/product&gt;</code></pre>
<p>Here is a valid type struct that will allow you to retrieve the value of myXml :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/CDATA/main.go 

type Product struct {
    Price Price  `xml:&quot;price&quot;`
    Name  string `xml:&quot;name&quot;`
    MyXml  MyXml `xml:&quot;myXml&quot;`
}

type MyXml struct {
    Text     string `xml:&quot;,cdata&quot;`
}</code></pre>
<p>Note that we created the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">MyXml</code></span> that has a single field <span v-highlightjs><code class="go" v-pre style="display: inline">Text</code></span>. This single field has the following tag :</p>
<pre v-highlightjs><code class="go" v-pre >`xml:&quot;,cdata&quot;`</code></pre>
<div id="comments" class="anchor"></div>
<h2 data-number="12.3"><span class="header-section-number">12.3</span> Comments <a href="#comments"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Unlike JSON, you can add comments in your XML :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/comments/main.go 

type Price struct {
    Text     string `xml:&quot;,chardata&quot;`
    Currency string `xml:&quot;currency,attr&quot;`
}

type Product struct {
    Comment string `xml:&quot;,comment&quot;`
    Price Price  `xml:&quot;price&quot;`
    Name  string `xml:&quot;name&quot;`
}</code></pre>
<p>Here we added a field named <span v-highlightjs><code class="go" v-pre style="display: inline">Comment</code></span> to our type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span>. The special <span v-highlightjs><code class="go" v-pre style="display: inline">comment</code></span> tag is added to the field.</p>
<p>Let’s take create a <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> and marshal it in XML :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/comments/main.go 

c := Product{}
c.Comment = &quot;this is my comment&quot;
c.Price = Price{Text : &quot;123&quot;,Currency:&quot;EUR&quot;}
c.Name = &quot;testing&quot;

b, err := xml.MarshalIndent(c,&quot;&quot;,&quot;      &quot;)
if err != nil {
    panic(err)
}
fmt.Println(string(b))</code></pre>
<p>This script will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;Product&gt;
        &lt;!--this is my comment--&gt;
        &lt;price currency=&quot;EUR&quot;&gt;123&lt;/price&gt;
        &lt;name&gt;testing&lt;/name&gt;
&lt;/Product&gt;</code></pre>
<div id="nested-fields" class="anchor"></div>
<h2 data-number="12.4"><span class="header-section-number">12.4</span> Nested fields <a href="#nested-fields"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A simple field can be nested into several XML elements :</p>
<pre v-highlightjs><code class="go" v-pre >// json-xml/xml/nesting/main.go
package main

import (
    &quot;encoding/xml&quot;
    &quot;fmt&quot;
)

type Product struct {
    Name string `xml:&quot;first&gt;second&gt;third&quot;`
}

func main() {
    c := Product{}
    c.Name = &quot;testing&quot;
    b, err := xml.MarshalIndent(c, &quot;&quot;, &quot;        &quot;)
    if err != nil {
        panic(err)
    }
    fmt.Println(string(b))
}</code></pre>
<p>The previous script will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;Product&gt;
        &lt;first&gt;
                &lt;second&gt;
                        &lt;third&gt;testing&lt;/third&gt;
                &lt;/second&gt;
        &lt;/first&gt;
&lt;/Product&gt;</code></pre>
<p>You can see that the value of the field <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> (<span v-highlightjs><code class="go" v-pre style="display: inline">"testing"</code></span>) is enclosed into the element <span v-highlightjs><code class="go" v-pre style="display: inline">"third"</code></span> which is also enclosed into the element <span v-highlightjs><code class="go" v-pre style="display: inline">"second"</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">"first"</code></span></p>
<p>That’s because we specified it in our struct tag :</p>
<pre v-highlightjs><code class="go" v-pre >`xml:&quot;first&gt;second&gt;third&quot;`</code></pre>
<div id="struct-type-generators" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Struct type generators <a href="#struct-type-generators"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Building a struct can be time consuming. Especially if you have to deal with complex JSON/XML structures. I can advise you to use those two tools :</p>
<ul>
<li><p>For JSON :</p>
<ul>
<li><p>https://github.com/mholt/json-to-go has been built by Matt Holt.</p></li>
<li><p>This is an online generator.</p></li>
<li><p>Simply copy your JSON, paste it and let the tool generate the corresponding struct(s).</p></li>
</ul></li>
<li><p>For XML :</p>
<ul>
<li><p>https://github.com/miku/zek</p></li>
<li><p>It has been made available by Martin Czygan.</p></li>
<li><p>This is a CLI tool that allows you to generate structs based on sample XML</p></li>
</ul></li>
</ul>
<div id="custom-json-marshaler-unmarshaler-advanced" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Custom JSON Marshaler / Unmarshaler (advanced) <a href="#custom-json-marshaler-unmarshaler-advanced"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In case you to use a <strong>specific logic</strong> for marshaling a type to JSON, you can create your <strong>custom</strong> Marshaler and Unmarshaler.</p>
<div id="custom-unmarshaler" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Custom Unmarshaler <a href="#custom-unmarshaler"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To create a custom JSON unmarshaler, you will have to implement the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshaler</code></span> interface.</p>
<p>Here is the definition of the interface :</p>
<pre v-highlightjs><code class="go" v-pre >type Unmarshaler interface {
    UnmarshalJSON([]byte) error
}</code></pre>
<p>It has a unique method specified : <span v-highlightjs><code class="go" v-pre style="display: inline">UnmarshalJSON([]byte) error</code></span></p>
<div id="example-time.time" class="anchor"></div>
<h3 data-number="14.1.1"><span class="header-section-number">14.1.1</span> Example: time.Time <a href="#example-time.time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s take an example implementation for the <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> type. JSON strings may contain time strings. Those time strings can be converted to time.Time values :</p>
<pre v-highlightjs><code class="go" v-pre >[
  {
    &quot;id&quot;: 1,
    &quot;first_name&quot;: &quot;Hedvig&quot;,
    &quot;last_name&quot;: &quot;Largen&quot;,
    &quot;created_at&quot;: &quot;2020-07-02T08:41:35Z&quot;
  },
  {
    &quot;id&quot;: 2,
    &quot;first_name&quot;: &quot;Win&quot;,
    &quot;last_name&quot;: &quot;Alloisi&quot;,
    &quot;created_at&quot;: &quot;2020-02-21T12:36:41Z&quot;
  }
]</code></pre>
<ul>
<li>In this JSON, we have a collection of persons. Each person has a created_date. We want to Unmarshal this data to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">users</code></span> (a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span>):</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type User struct {
    ID int `json:&quot;id&quot;`
    Firstname string `json:&quot;first_name&quot;`
    Lastname string `json:&quot;last_name&quot;`
    CreatedDate time.Time `json:&quot;created_at&quot;`
}
users := make([]User,2)</code></pre>
<p>For each type, Go will check if it has a custom unmarshaler.</p>
<ul>
<li><p>The type time.Time defines a custom JSON unmarshaller.</p></li>
<li><p>In other words, the type has a method named <span v-highlightjs><code class="go" v-pre style="display: inline">UnmarshalJSON</code></span> with the signature <span v-highlightjs><code class="go" v-pre style="display: inline">([]byte) error</code></span></p></li>
<li><p>In other words <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> implements the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshaler</code></span> interface</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// UnmarshalJSON implements the json.Unmarshaler interface.
// The time is expected to be a quoted string in RFC 3339 format.
func (t *Time) UnmarshalJSON(data []byte) error {
    // Ignore null, like in the main JSON package.
    if string(data) == &quot;null&quot; {
        return nil
    }
    // Fractional seconds are handled implicitly by Parse.
    var err error
    *t, err = Parse(`&quot;`+RFC3339+`&quot;`, string(data))
    return err
}</code></pre>
<ul>
<li><p>In this method, the value null is ignored.</p>
<ul>
<li>If there is a “null” in the JSON string for the property <span v-highlightjs><code class="go" v-pre style="display: inline">"created_at"</code></span> then the value of the field <span v-highlightjs><code class="go" v-pre style="display: inline">CreatedAt</code></span> will be the zero value of time.Time, which is January 1, year 1, 00:00:00.000000000 UTC.</li>
</ul></li>
<li><p>Then the method will attempt to parse the value stored in data :</p>
<pre v-highlightjs><code class="go" v-pre >*t, err = Parse(`&quot;`+RFC3339+`&quot;`, string(data))</code></pre>
<ul>
<li><p>This line will parse the time with the format <span v-highlightjs><code class="go" v-pre style="display: inline">"2006-01-02T15:04:05Z07:00"</code></span> (<span v-highlightjs><code class="go" v-pre style="display: inline">RFC3339</code></span> is a constant of the package)</p></li>
<li><p>Note that the enclosing double quotes are enclosing the parsing format.</p></li>
<li><p>That’s because the value in JSON has the format<span v-highlightjs><code class="go" v-pre style="display: inline">"2020-07-02T08:41:35Z"</code></span> and not <span v-highlightjs><code class="go" v-pre style="display: inline">2020-07-02T08:41:35Z</code></span> !</p></li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">time.Parse</code></span> returns the value parsed or an error</p>
<ul>
<li><p>The value parsed is assigned to <span v-highlightjs><code class="go" v-pre style="display: inline">*t</code></span></p>
<ul>
<li>We use the dereference operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span> to set the value at the <span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span> address (<span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span> is a pointer type)</li>
</ul></li>
</ul>
<div id="custom-marshaller" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Custom Marshaller <a href="#custom-marshaller"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is the interface that you will need to implement on your type :</p>
<pre v-highlightjs><code class="go" v-pre >type Marshaler interface {
    MarshalJSON() ([]byte, error)
}</code></pre>
<p>It is composed of one method named <span v-highlightjs><code class="go" v-pre style="display: inline">MarshalJSON</code></span> with the signature <span v-highlightjs><code class="go" v-pre style="display: inline">() ([]byte, error)</code></span>.</p>
<ul>
<li><p>A custom marshaller will output a slice of byte, which is the JSON marshaled</p></li>
<li><p>And an error.</p></li>
</ul>
<div id="example-time.time-1" class="anchor"></div>
<h3 data-number="14.2.1"><span class="header-section-number">14.2.1</span> Example: time.Time <a href="#example-time.time-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// MarshalJSON implements the json.Marshaler interface.
// The time is a quoted string in RFC 3339 format, with sub-second precision added if present.
func (t Time) MarshalJSON() ([]byte, error) {
    if y := t.Year(); y &lt; 0 || y &gt;= 10000 {
        // RFC 3339 is clear that years are four digits exactly.
        // See golang.org/issue/4556#c15 for more discussion.
        return nil, errors.New(&quot;Time.MarshalJSON: year outside of range [0,9999]&quot;)
    }

    b := make([]byte, 0, len(RFC3339Nano)+2)
    b = append(b, &#39;&quot;&#39;)
    b = t.AppendFormat(b, RFC3339Nano)
    b = append(b, &#39;&quot;&#39;)
    return b, nil
}</code></pre>
<p>Here is the implementation of <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshaller</code></span> interface for the type <span v-highlightjs><code class="go" v-pre style="display: inline">Time</code></span>. The idea is to transform a value of type Time into its JSON representation.</p>
<ul>
<li><p>First, the year is checked; it must be between 0 and 9999 (no more than four digits)</p></li>
<li><p>Then a slice of byte <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> is created.</p>
<ul>
<li><p>The slice has a length equal to 0, and a capacity of <span v-highlightjs><code class="go" v-pre style="display: inline">len(RFC3339Nano)+2</code></span>.</p></li>
<li><p>The time will be outputed following the format described in the constant<span v-highlightjs><code class="go" v-pre style="display: inline">RFC3339Nano</code></span></p></li>
<li><p>2 is added to the format’s length for handling the two double quotes (2 bytes).</p></li>
</ul></li>
<li><p>A single, double quote is then added to the slice</p></li>
<li><p>Then the time formatted is appended to the slice with the method <span v-highlightjs><code class="go" v-pre style="display: inline">AppendFormat</code></span></p></li>
<li><p>Another double quote is added to <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> (the closing one)</p></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Which method can you use to convert a variable into JSON?</p></li>
<li><p>Which method can you use to convert a JSON string into a variable of a certain type?</p></li>
<li><p>How to change how a field name on a struct is displayed into JSON?</p></li>
<li><p>How to change how a field name on a struct is displayed into XML?</p></li>
<li><p>What is the difference between encoding and marshaling?</p></li>
<li><p>How to customize the JSON output of a type?</p></li>
<li><p>How to customize how Go will parse an element of a given type in JSON?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Which method can you use to convert a variable into JSON?</p>
<pre v-highlightjs><code class="go" v-pre >json.Marshal</code></pre></li>
<li><p>Which method can you use to convert a JSON string into a variable of a certain type?</p>
<pre v-highlightjs><code class="go" v-pre >json.Unmarshal</code></pre></li>
<li><p>How to change how a field name on a struct is displayed into JSON?</p>
<ol type="1">
<li><p>With a struct tag</p></li>
<li><p>Ex :</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type User struct {
    ID int `json:&quot;id&quot;`
}</code></pre>
<ol type="1">
<li>The struct tag is <span v-highlightjs><code class="go" v-pre style="display: inline">`json:"id"`</code></span></li>
</ol></li>
<li><p>How to change how a field name on a struct is displayed into XML?</p>
<ol type="1">
<li><p>With a struct tag</p></li>
<li><p>ex : <span v-highlightjs><code class="go" v-pre style="display: inline">`xml:"id"`</code></span></p></li>
</ol></li>
<li><p>What is the difference between encoding and marshaling?</p>
<ol type="1">
<li><p>Encoding and marshaling refer to the same process</p></li>
<li><p>The term encoding is used when you have to transform a data stream into a coded form</p></li>
<li><p>Marshaling is used when you have to transform a variable into a coded form</p></li>
</ol></li>
<li><p>How to customize the JSON representation of a type?</p>
<ol type="1">
<li>Implement the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshaler</code></span> interface on your type</li>
</ol></li>
<li><p>How to customize how Go will parse an element of a given type in JSON?</p>
<ol type="1">
<li>Implement the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshaler</code></span> interface on your type</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>JSON (JavaScript Object Notation) is a popular format used to represent structured data easily readable by a human or machine.</p></li>
<li><p>XML (e <strong>X</strong>tensible <strong>M</strong>arkup <strong>L</strong>anguage) is also used to represent structured data.</p></li>
<li><p>“convert a variable into a coded form” = “<strong>marshaling</strong>”</p></li>
<li><p>“convert a coded form into a variable” = “<strong>unmarshaling</strong>”</p></li>
<li><p>“convert a data stream into a coded form” = “<strong>encoding</strong>”</p></li>
<li><p>“convert a coded form into a data stream” = “<strong>decoding</strong>”</p></li>
<li><p>To convert a variable into JSON/XML, we can use <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshal</code></span> / <span v-highlightjs><code class="go" v-pre style="display: inline">xml.Marshal</code></span></p></li>
<li><p>To convert JSON/XML into a variable, we can use <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> / <span v-highlightjs><code class="go" v-pre style="display: inline">xml.Unmarshal</code></span></p></li>
<li><p>Struct tags allow you to modify how a field will be outputed in JSON/XMLName</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    Price Price  `xml:&quot;price&quot;`
    Name  string `xml:&quot;name&quot;`
    MyXml  MyXml `xml:&quot;myXml&quot;`
}</code></pre></li>
<li><p>To get the value of a struct tag, you will have to use the <span v-highlightjs><code class="go" v-pre style="display: inline">reflect</code></span> standard package</p>
<pre v-highlightjs><code class="go" v-pre >p:= Product{ID:32}
t := reflect.TypeOf(p)
for i := 0; i &lt; t.NumField(); i++ {
    fmt.Printf(&quot;field Name : %s\n&quot;,t.Field(i).Name)
    fmt.Printf(&quot;field Tag : %s\n&quot;,t.Field(i).Tag)
}</code></pre></li>
<li><p>Some specific struct tags values</p>
<ul>
<li><p>Skip a field : <span v-highlightjs><code class="go" v-pre style="display: inline">`json:"-"`</code></span> (will be hidden in JSON, XML)</p></li>
<li><p>Do not output empty values : <span v-highlightjs><code class="go" v-pre style="display: inline">`json:"first_name,omitempty"`</code></span></p></li>
</ul></li>
<li><p>You can combine struct tags for JSON with other struct tags</p>
<ul>
<li>For instance xml struct tags</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type User struct {
        ID int `json:&quot;id&quot; xml:&quot;id&quot;`
}</code></pre></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Panic is not recommended in a real-life program; you should handle the error and use a proper exit logic<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap25JsonAndXml"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap24AnonymousFunctionsAndClosures'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Anonymous functions & closures</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap26BasicHttpServer'}">
									<p><u><small>Next</small></u></p>
									<p><small>Basic HTTP Server</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "JSON and XML - Practical Go Lessons"
const description = "SON and XML are two formats that are used in modern applications. They are very convenient because they can be read and easily understood by humans in comparison to other formats . How to unmarshal and marshal JSON, XML"

export default {
  name: "Chap25JsonAndXml",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
